import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { DefaultError, UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';

export interface Request {
  userId: string;
  activity?: 'break' | 'work';
  auto?: boolean;
}

export type Options = Omit<UseMutationOptions<string, DefaultError, Request>, 'mutationKey' | 'mutationFn'>;

export const useJibbleClockOutMutation = (options?: Options) => {
  const queryClient = useQueryClient();
  const _mutation = async (value: Request): Promise<string> => {
    const { activity, auto } = value;
    const { data } = await axiosInterceptorInstance.post(`admins/${value.userId}/clock-out`, {
      activity,
      auto,
    });
    return data;
  };

  return useMutation({
    mutationKey: ['admins', 'clock-out'],
    mutationFn: _mutation,
    ...options,
    onSettled: (data, error) => {
      if (!error) {
        queryClient.invalidateQueries({
          queryKey: ['admins/activity/status'],
          refetchType: 'all',
        });
      }
    },
  });
};
