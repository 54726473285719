export enum PERMISSIONS_TYPES {
  /**
   * User Management:
   * Permission to view the page with a table of all users.
   */
  VIEW_ADMIN_USER_LIST,
  /**
   * Admin Management:
   * Permission to create new admin users.
   */
  CREATE_NEW_ADMIN,
  /**
   * Admin Management:
   * Permission to view the admin user profile page.
   */
  VIEW_ADMIN_PROFILE,
  /**
   * Admin Management:
   * Permission to edit the admin user profile.
   */
  CHANGE_ADMIN_PROFILE,
  /**
   * User Management:
   * Permission to resend invitation links to therapists, guardians, and admins.
   */
  RESEND_INVITATION_LINK,
  /**
   * Admin Management:
   * Permission to soft delete an admin user.
   */
  SOFT_DELETE_ADMIN_USER,
  /**
   * Therapists Management:
   * Permission to create a therapist account.
   */
  CREATE_THERAPIST,
  /**
   * Therapists Management:
   * Permission to set the pay rate.
   */
  SET_PAY_RATE,
  /**
   * Therapists Management:
   * Permission to view all therapists.
   */
  VIEW_ALL_THERAPISTS,
  /**
   * Therapists Management:
   * Permission to manage therapist details.
   */
  MANAGE_THERAPIST_DETAILS,
  /**
   * Therapists Management:
   * Permission to soft delete a therapist profile.
   */
  SOFT_DELETE_THERAPIST_PROFILE,
  /**
   * Service Roles Management:
   * Permission to view the list of service roles.
   */
  VIEW_LIST_OF_SERVICE_ROLES,
  /**
   * Service Roles Management:
   * Permission to create a service role.
   */
  CREATE_SERVICE_ROLE,
  /**
   * Service Roles Management:
   * Permission to soft delete a service role.
   */
  SOFT_DELETE_SERVICE_ROLE,
  /**
   * Client Management:
   * Permission to create a client profile.
   */
  CREATE_CLIENT,
  /**
   * Client Management:
   * Permission to create a guardian profile.
   */
  CREATE_GUARDIAN,
  /**
   * Client Management:
   * Permission to view all clients.
   */
  VIEW_ALL_CLIENTS,
  /**
   * Client Management:
   * Permission to edit client details.
   */
  EDIT_CLIENT,
  /**
   * Client Management:
   * Permission to delete a client profile.
   */
  DELETE_CLIENT,
  /**
   * Client Management:
   * Permission to view guardians list.
   */
  VIEW_ALL_GUARDIANS,
  /**
   * Guardians Management:
   * Permission to edit guardian details.
   */
  EDIT_GUARDIAN,
  /**
   * Guardians Management:
   * Permission to delete a guardian profile.
   */
  DELETE_GUARDIAN,
  /**
   * Service Management:
   * Permission to create a service.
   */
  CREATE_SERVICE,
  /**
   * Service Management:
   * Permission to decline services.
   */
  DECLINE_SERVICE,
  /**
   * Service Management:
   * Permission to view all services.
   */
  VIEW_ALL_SERVICES,
  /**
   * Service Management:
   * Permission to edit service details.
   */
  EDIT_SERVICE,
  /**
   * Service Management:
   * Permission to soft delete a service.
   */
  SOFT_DELETE_SERVICE,
  /**
   * Document Management:
   * Permission to view all documents.
   */
  VIEW_ALL_DOCUMENTS,
  /**
   * Document Management:
   * Permission to view my documents.
   */
  VIEW_MY_DOCUMENTS,
  /**
   * Document Management:
   * Permission to submit document.
   */
  SUBMIT_DOCUMENT,
  /**
   * Document Management:
   * Permission to edit document.
   */
  EDIT_DOCUMENT,
  /**
   * Document Management:
   * Permission to pre submit document for therapist.
   */
  PRESUBMIT_DOCUMENT,
  /**
   * Document Management:
   * Permission to approve or reject a report.
   */
  APPROVE_OR_REJECT_REPORT,
  /**
   * Document Management:
   * Permission to manually create a document.
   */
  MANUAL_CREATION_OF_DOCUMENT,
  /**
   * Document Management:
   * Permission to soft delete a document.
   */
  SOFT_DELETE_DOCUMENT,
  /**
   * Schedule Management:
   * Permission to view the full schedule.
   */
  VIEW_FULL_SCHEDULE,
  /**
   * Schedule Management:
   * Permission to view my schedule.
   */
  MY_SCHEDULE,
  /**
   * Therapist profile:
   * Permission to view ADP ID.
   */
  VIEW_ADP_ID,
  /**
   * Schedule Management:
   * Permission to add shifts to the schedule.
   */
  ADD_SHIFTS,
  /**
   * Schedule Management:
   * Permission to add trips to the schedule.
   */
  ADD_TRIPS,
  /**
   * Therapist Management:
   * Permission to preview the therapist's profile.
   */
  PREVIEW_THERAPIST_PROFILE,
  /**
   * Therapist Attestation:
   * Permission to show attestation dialog.
   */
  SHOW_DAILY_THERAPIST_ATTESTATION,
  /**
   * Edit clock in and clock out:
   * Permission to change clock in and clock out.
   */
  EDIT_SHIFTS_CLOCK_IN_CLOCK_OUT,
  /**
   * Shift Management:
   * Permission to add shifts with admin privileges.
   */
  ADD_SHIFTS_LIKE_ADMINS,

  /**
   * Shift Management:
   * Permission to add shifts without admin privileges.
   */
  ADD_SHIFTS_LIKE_NOT_ADMINS,
  /**
   * Schedule Management:
   * Permission to change shift details.
   */
  CHANGE_SHIFT_DETAILS,
  /**
   * Shift Management:
   * Permission to change shift notes.
   */
  CHANGE_SHIFT_NOTES,
  /**
   * Schedule Management:
   * Permission to trigger SMS for guardian with link to sign shift one more time.
   */
  TRIGGER_SMS_FOR_SIGN_SHIFT,
  /**
   * Schedule Management:
   * Permission to change shift details with statuses COMPLETED OR NO_SHOW.
   */
  CHANGE_SHIFT_DETAILS_COMPLETED_NO_SHOW,
  /**
   * Schedule Management:
   * Permission to soft delete a shift.
   */
  SOFT_DELETE_SHIFT,
  /**
   * Schedule Management:
   * Permission to soft delete a shift with status NO SHOW.
   */
  SOFT_DELETE_SHIFT_NO_SHOW,
  /**
   * Schedule Management:
   * Permission to cancel a shift.
   */
  CANCEL_SHIFT,
  /**
   * Schedule Management:
   * Permission to cancel a shift with status COMPLETED.
   */
  CANCEL_COMPLETED_SHIFT,
  /**
   * Shift Management:
   * Permission to view canceled shifts.
   */
  VIEW_CANCEL_SHIFTS,
  /**
   * Chats Management:
   * Permission to manage department chats.
   */
  DEPARTMENTS_MANAGE,
  /**
   * Activity Log Management:
   * Permission to view activity logs.
   */
  VIEW_ACTIVITY_LOGS,
  /**
   * Reports Management:
   * Permission to view reports.
   */
  VIEW_REPORTS,
  /**
   * Reports Management:
   * Permission to view billing report table.
   */
  VIEW_BILLING_REPORT,
  /**
   * Timesheets Management:
   * Permission to view the list of timesheets.
   */
  VIEW_TIMESHEETS_LIST,
  /**
   * Timesheets Management:
   * Permission to view the list of my timesheets.
   */
  VIEW_MY_TIMESHEETS_LIST,
  /**
   * Timesheets Management:
   * Permission to allow view total pay metrics.
   */
  VIEW_TOTAL_PAY_METRIC,
  /**
   * Timesheets Management:
   * Permission to allow view pay rates metrics.
   */
  VIEW_PAY_RATES_METRIC,
  /**
   * Timesheets Management:
   * Permission to approve the payroll.
   */
  APPROVE_PAYROLL,
  /**
   * Available Clients Management:
   * Permission to view the list of available clients.
   */
  AVAILABLE_CLIENTS_LIST,
  /**
   * Available Clients Management:
   * Permission to view my list of clients.
   */
  MY_CLIENTS_LIST,
  /**
   * Available Clients Management:
   * Permission to create an unpublished shift for contacting parents.
   */
  CREATE_UNPUBLISHED_SHIFT_FOR_CONTACTING_PARENTS,
  /**
   * Available Clients Management:
   * Permission to authorize the service.
   */
  AUTHORIZATION_OF_THE_SERVICE,
  /**
   * Available Clients Management:
   * Permission to manage on-hold services.
   */
  MANAGE_ON_HOLD_SERVICES,
  /**
   * Services Management:
   * Permission to terminate services.
   */
  TERMINATE_SERVICES,
  /**
   * Services Management:
   * Permission to reinstate services.
   */
  REINSTATE_SERVICES,
  /**
   * Archive Management:
   * Permission to view and filter archived items.
   */
  VIEW_FILTER_ARCHIVED,
  /**
   * Shift Management:
   * Permission to view my shifts.
   */
  VIEW_MY_SHIFTS,
  /**
   * Comments Management:
   * Permission to edit comments.
   */
  EDIT_COMMENTS,
  /**
   * Comments Management:
   * Permission to remove comments.
   */
  REMOVE_COMMENTS,
  /**
   * Clock In/Out Jibble:
   * Permission to allow to access to App only with Clock In/Out Jibble.
   */
  VIEW_JIBBLE_TRACKER_OPTIONS,
}
