import { jwtDecode } from 'jwt-decode';
import CredentialsProvider from 'next-auth/providers/credentials';
import GoogleProvider from 'next-auth/providers/google';
import axios from 'axios';
import mem from 'mem';

import type { JWT, NextAuthOptions } from 'next-auth';
import { AccessToken } from './common/interfaces';
import { AUTH_JWT_ACTIONS } from './common/enums';

const REFRESH_TOKEN_ENDPOINT = `${process.env.NEXT_PUBLIC_API_URL}/auth/refresh/`;
const SIGN_IN_ENDPOINT = `${process.env.NEXT_PUBLIC_API_URL}/auth/sign-in/`;
const GOOGLE_VERIFY_ENDPOINT = `${process.env.NEXT_PUBLIC_API_URL}/auth/google/verify/`;

export const refreshAccessToken = mem(
  async (refreshTokenId: string): Promise<JWT | { error: string } | null> => {
    try {
      const response = await axios.post(REFRESH_TOKEN_ENDPOINT, { tokenId: refreshTokenId });
      if (response) {
        const { accessToken, refreshTokenId: refreshToken, sendBird, refreshTokenExpiresAt, jibbleStatus } = response.data;
        const decoded = jwtDecode<AccessToken>(response.data.accessToken);
        return {
          ...decoded,
          jibbleStatus,
          refreshToken,
          accessToken,
          sendBird,
          refreshTokenExpiresAt,
        } as JWT;
      } else {
        console.error('=========== REFRESH TOKEN API WARNING ===========');
        console.error(response);
        console.error('=========== ========================= ===========');
        return {
          error: 'RefreshAccessTokenError',
        };
      }
    } catch (error: any) {
      console.error('=========== REFRESH TOKEN ERROR ===========');
      console.error(error.response.data);
      console.error('=========== =================== ===========');
      return {
        error: 'RefreshAccessTokenError',
      };
    }
  },
  {
    maxAge: 5_000,
    cacheKey: (arguments_) => arguments_.join(','),
  }
);

export const authOptions: NextAuthOptions = {
  providers: [
    GoogleProvider({
      clientId: <string>process.env.GOOGLE_CLIENT_ID,
      clientSecret: <string>process.env.GOOGLE_CLIENT_SECRET,
      authorization: {
        params: {
          prompt: 'consent',
          access_type: 'offline',
          response_type: 'code',
        },
      },
    }),
    CredentialsProvider({
      name: 'Credentials',
      credentials: {
        email: { label: 'Email', type: 'text' },
        password: { label: 'Password', type: 'password' },
      },
      async authorize(credentials) {
        if (typeof credentials !== 'undefined') {
          const { email, password } = credentials;
          try {
            const response = await axios.post(SIGN_IN_ENDPOINT, { email, password });
            const { accessToken, refreshTokenId: refreshToken, sendBird, refreshTokenExpiresAt, jibbleStatus } = response.data;
            const decoded = jwtDecode<AccessToken>(accessToken);

            const user: any = {
              refreshToken,
              accessToken,
              sendBird,
              jibbleStatus,
              refreshTokenExpiresAt,
              ...decoded,
            };
            return user;
          } catch (error: any) {
            if (error.response.status === 503) {
              error.response.data = { message: 'Service is temporarily unavailable' };
            }
            throw error.response.data;
          }
        } else {
          return null;
        }
      },
    }),
  ],
  session: {
    strategy: 'jwt',
    maxAge: 30 * 24 * 60 * 60, // 30 days
  },
  secret: process.env.NEXTAUTH_SECRET,
  callbacks: {
    async signIn({ account, profile, user }: any) {
      if (account && account.provider === 'google') {
        if (!profile.email_verified) return false;

        try {
          const response = await axios.post(GOOGLE_VERIFY_ENDPOINT, { idToken: account?.id_token });
          const { accessToken, refreshTokenId: refreshToken, sendBird, refreshTokenExpiresAt } = response.data;
          const decoded: AccessToken = jwtDecode(response.data.accessToken);
          user.userId = decoded.userId;
          user.firstName = decoded.firstName;
          user.lastName = decoded.lastName;
          user.roles = decoded.roles;
          user.refreshToken = refreshToken;
          user.accessToken = accessToken;
          user.refreshTokenExpiresAt = refreshTokenExpiresAt;
          user.sendBird = sendBird;
          return true;
        } catch (error: any) {
          throw error.response.data;
        }
      }
      return true; // Do different verification for other providers that don't have `email_verified`
    },
    async session({ session, token }) {
      const sanitizedToken = Object.keys(token).reduce((p, c) => {
        // strip unnecessary properties
        if (c !== 'iat' && c !== 'exp' && c !== 'jti') {
          // if (c !== 'iat' && c !== 'exp' && c !== 'jti' && c !== 'accessToken' && c !== 'refreshToken') {
          return { ...p, [c]: token[c] };
        } else {
          return p;
        }
      }, {});
      return {
        ...session,
        user: sanitizedToken,
        accessToken: token.accessToken,
        refreshToken: token.refreshToken,
      } as any;
    },
    async jwt({ token, user, trigger, session }): Promise<any> {
      if (typeof user !== 'undefined') {
        // user has just signed in so the user object is populated
        return user;
      }

      const { refreshToken } = token as JWT;

      if (trigger === 'update') {
        if (session.action === AUTH_JWT_ACTIONS.REFRESH) {
          let newTokenData = await refreshAccessToken(refreshToken);
          return newTokenData;
        }

        if (session.action === AUTH_JWT_ACTIONS.UPDATE_ROLE && session.data) {
          token.roles = [session.data.role];
          return token;
        }

        if (session.action === AUTH_JWT_ACTIONS.UPDATE_JIBBLE_STATUS && session.data) {
          token.jibbleStatus = session.data.jibbleStatus;
          return token;
        }

        if (session.action === AUTH_JWT_ACTIONS.REFRESH_MANUAL && session.data) {
          const decoded = jwtDecode<AccessToken>(session.data.accessToken);
          token.roles = decoded.roles;
          token.accessToken = session.data.accessToken;
          token.refreshToken = session.data.refreshToken;
          token.sendBird = session.data.sendBird;
          token.refreshTokenExpiresAt = session.data.refreshTokenExpiresAt;
          return token;
        }
      }

      return token;
    },
  },
};
