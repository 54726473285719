import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { DefaultError, useQuery, UseQueryOptions } from '@tanstack/react-query';

export enum JIBBLE_STATUSES {
  ACTIVE = 'Active', // if the last action was a clock-in
  INACTIVE = 'Inactive', // if there was no clock-in in the system for the day
  CLOCKED_OUT = 'Clocked-out', // if the last action was a clock-out
  BREAK = 'Break', // if the last action was a break
}

export interface JibbleStatusResponse {
  status: JIBBLE_STATUSES;
}

export type Options = Omit<UseQueryOptions<unknown, DefaultError, JibbleStatusResponse | null>, 'queryKey' | 'queryFn'>;

export const useJibbleClockInStatusQuery = (options?: Options) => {
  const _query = async (): Promise<JibbleStatusResponse | null> => {
    const { data } = await axiosInterceptorInstance.get(`admins/activity/status`);
    return data;
  };

  return useQuery({
    queryKey: [`admins/activity/status`],
    queryFn: _query,
    refetchOnMount: false,
    ...options,
  });
};
